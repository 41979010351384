import React, { useContext, useState } from 'react';
import loginIcon from '../assets/icons/Login-Icon.svg';
import { MobileContext } from './Layout';
import popUpIconClose from '../assets/icons/popUpIconClose.svg';
import loginBulb from '../assets/images/loginBulb.png';
import Button from './Button';

const PatientenQuickLogin_Mobile = () => {
    const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
    const isMobile = useContext(MobileContext);
    const [open, setOpen] = useState( (urlParams.get('open') === 'patientenlogin') ? true : false);
    const [buttonEnabled, setButtonEnabled] = useState(false);

    const handleClick = () => {
        setOpen(false)
        urlParams.delete('open');

        window.location.search = urlParams.toString()
    };

    function validateCode(code) {

        const regex1 = /^[1-9][A-Za-z][1-9]{3}[A-Za-z]$/;
        const regex2 = /^[1-9][A-Za-z][1-9]{2}[A-Za-z]{2}$/;
        const regex3 = /^[A-Za-z]{2}[1-9]{4}$/;
        const regex4 = /^[1-9][A-Za-z]{2}[1-9]{2}[A-Za-z]$/;
        const regex5 = /^[1-9][A-Za-z][1-9]{4}$/;
        const regex6 = /^[1-9][A-Za-z]{2}[1-9]{2}[A-Za-z]$/;

        console.log(code)

        return regex1.test(code) || regex2.test(code) || regex3.test(code) || regex4.test(code) || regex5.test(code) || regex6.test(code);
    }

    function validateNumberInput(numberInput) {

        let chargeNumber = numberInput.toUpperCase();

        setButtonEnabled(validateCode(chargeNumber));
    }

    
    function userLogin() {
        let isUserLoggedIn = window.sessionStorage.getItem("logged-in");

		if (buttonEnabled) {
			if ( isUserLoggedIn == '' || isUserLoggedIn == null || isUserLoggedIn == 'undefined' ) {
				window.sessionStorage.setItem("logged-in", true);
			}
		}
    }

    function userLogout() {
        let isUserLoggedIn = window.sessionStorage.getItem("logged-in");

        if ( isUserLoggedIn == '' || isUserLoggedIn == null || isUserLoggedIn == 'undefined' ) {
            return;
        }

        sessionStorage.removeItem("logged-in");
        window.location.replace("/");
    }

    return (
        <div className="patient-login">
            <div className={`popUpContainer ${open ? '' : 'hidden'}`}>
                <h2 className="headerLogin">
                    ZUGANG FÜR <br /> PATIENT/-INNEN
                </h2>
                <p className="textLogin">
                    Chargennummer (Ch.-B.) auf der Packung:
                </p>
                <img
                    onClick={handleClick}
                    className="patient-icon-close"
                    src={popUpIconClose}></img>

                <input
                    placeholder="Nummer eintragen"
                    onChange={(e) => {
                        validateNumberInput(e.target.value);
                    }}
                />
                <Button
                    type="primary"
                    text="Patienten Login"
                    style={buttonEnabled ? {} : { opacity: 0.2 }}
                    to={
                        buttonEnabled
                            ? '/patientenlogin/homepage/'
                            : null
                    }
                    onClick={userLogin}
                />
                <div className="infoContainer">
                    <div>
                        <img src={loginBulb}></img>
                    </div>
                    <div>
                        <h4>Jetzt anmelden</h4>
                        <p>
                            Dir wurde zur Behandlung Deiner Neurodermitis eine
                            Therapie von Sanofi verordnet. Im Login-Bereich
                            findest Du weitere Informationen zum Produkt und
                            dessen Anwendung. Du kannst Dich mit der
                            Chargennummer (Ch.-B.) auf der Packung des
                            Medikaments hier anmelden.
                        </p>
                    </div>
                </div>
            </div>
            <div className={`overlay ${open ? '' : 'hidden'}`}></div>
        </div>
    );
};

export default PatientenQuickLogin_Mobile;
